export default class NumUtil {
  static formatNum(num: number): string {
    if (num == 0) {
      return '0';
    }
    if (num < 0) {
      return num.toString(); // 负数，不进行格式化
    }
    const units = ['', 'k', 'm', 'b', 't']; // 单位数组
    const unitIndex = Math.floor(Math.log10(num) / 3);

    if (unitIndex >= units.length) {
      return num.toString(); // 数字太大，不进行格式化
    }

    if (unitIndex === 0) {
      return num.toString(); // 小于1000，返回整数形式
    }

    const formattedNumber = num / Math.pow(10, unitIndex * 3);
    return `${formattedNumber.toFixed(1)}${units[unitIndex]}`;
  }

  static dealDecimal(
    num: number,
    format: boolean = false,
    count: number = 1
  ): string {
    // 整数无需保留小数点，默认不格式化
    if (Number.isInteger(num)) {
      return format ? this.formatNum(num) : num.toString();
    }
    // 浮点数保留后count位，默认1位
    const result = num.toFixed(count);
    // 如果小数点都是0，则去掉取整
    return result % 1 === 0 ? parseInt(result) : result;
  }

  static isNumber(value: object): boolean {
    // 是否是数值类型
    return !Number.isNaN(Number(value));
  }
}
